import React from "react"
import intern from "../../assets/images/banners/job-images/brooke-cagle-g1Kr4Ozfoac-unsplash.jpg"
import professional from "../../assets/images/banners/job-images/headway-5QgIuuBxKwM-unsplash.jpg"
import { Link } from "gatsby"
const Career = () => {
  return (
    <div id="main-wrapper">
      <div className="site-wrapper-reveal">
        <div
          className="about-banner-wrap banner-space bg-img careers"
          data-bg="assets/images/banners/careers.jpg"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-8 m-auto">
                <div className="about-banner-content text-center">
                  <h1 className="mb-15 text-white">Careers</h1>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!--====================   Job Section Start ====================--> */}
        <div className="contact-us-section-wrappaer section-space--ptb_80">
          <div className="section-title-wrap text-center section-space--mb_60">
            <h2 className="heading">Your Career With Us, <span className="text-color-primary"> Join and Grow</span></h2>
          </div>

          <div className="container">
            <div className="row">
              
              <div className="col-lg-4 mb-20">
                <div className="">
                  <div>
                    <Link to="/internship-job" className="card__button1">
                      <img className="job__image1" src={intern} />
                    </Link>
                  </div>
                  <div className="mt-3">
                    <h3 className="card__title1">INTERNS JOB OPENINGS</h3>
                    <p className="card__snippet1">
                      Interns assist the company with tasks set out by various
                      teams, such as research, data capturing and working closely
                      with different team members to learn more about the
                      company...
                    </p>
                    <Link to="/internship-job" className="card__button1">
                      View Job
                    </Link>
                  </div>
                </div>
              </div>

              <div className="col-lg-4 mb-20">
                <div className="">
                  <div>
                    <Link to="/professional-service" className="card__button1">
                      <img className="job__image1" src={professional} />
                    </Link>
                  </div>
                  <div className="mt-3">
                    <h2 className="card__title1">PROFESSIONAL SERVICES</h2>
                    <p className="card__snippet1">
                      For the employee, the job description describes the
                      responsibilities, tasks, and related duties of a position.
                      It will often include additional information such as
                      education, qualifications, and skills required for the role.
                    </p>
                    <Link to="/professional-service" className="card__button1">
                      View Job
                    </Link>
                  </div>
                </div>
              </div>

            </div>

            {/* <div className="cards">
              <figure className="card__thumb">
                <img className="job__image" src={intern} />
                <figcaption className="card__caption">
                  <h3 className="card__title">INTERNS JOB OPENINGS</h3>
                    <p className="card__snippet">
                      Interns assist the company with tasks set out by various
                      teams, such as research, data capturing and working closely
                      with different team members to learn more about the
                      company...
                    </p>
                    <Link to="/internship-job" className="card__button">
                      View Job
                    </Link>
                </figcaption>
              </figure>
            </div>
            <div className="cards">
              <figure className="card__thumb">
                <img
                  className="job__image"
                  src={professional}
                  alt="Picture by Nathan Dumlao"
                />
                <figcaption className="card__caption">
                  <h2 className="card__title">PROFESSIONAL SERVICES</h2>
                  <p className="card__snippet">
                    For the employee, the job description describes the
                    responsibilities, tasks, and related duties of a position.
                    It will often include additional information such as
                    education, qualifications, and skills required for the role.
                  </p>
                  <Link to="/professional-service" className="card__button">
                    View Job
                  </Link>
                </figcaption>
              </figure>
            </div> */}

          </div>
          {/* <!--====================  Job Section End  ====================--> */}
        </div>





      </div>
    </div>
  )
}
export default Career
