import * as React from "react"
import Layout from "../components/layout"
import Career from "../components/Index/Career"






const career = () => {
  return (
  <Layout>
   <Career/>
  </Layout>
)
};
export default career;
